import React from 'react';
import { ChartComponent, SeriesCollectionDirective, SplineAreaSeries, SeriesDirective, Inject, DateTime, Legend } from '@syncfusion/ej2-react-charts';
import { areaCustomSeries, areaPrimaryYAxis, areaPrimaryXAxis } from '../../data/dummy';
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';


const Area = () => {
  const { currentColor } = useStateContext();

  return (
    <div>
      <Header category="Area" title="Inflation Rate in Precentage" />

      <ChartComponent
        id='line-chart'
        height="420px"
        primaryXAxis={areaPrimaryXAxis}
        primaryYAxis={areaPrimaryYAxis}
        chartArea={{ border: { width: 0 } }}
        tooltip={{ enable: true }}
        background={currentColor === "Dark" ? '#33373e' : '#fff'}
      >
        <Inject
          services={[SplineAreaSeries, DateTime, Legend]}
        />
        <SeriesCollectionDirective>
          {areaCustomSeries.map((item, index) => <SeriesDirective key={index} {...item} />)}
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  )
}

export default Area