import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notifications: false,
};

export  const ContextProvider = ({ children }) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [isClicked, setIsClicked] = useState(initialState);
    const [screenSize, setScreenSize] = useState(undefined);
    const [currentColor, setCurrentColor] = useState('#03c9d7');
    const [currentMode, setCurrentMode] = useState('Light');
    const [themeSettings, setThemeSettings] = useState(false);
    const [activeCart, setActiveCart] = useState(false)



    const setMode =(e) =>{
      setCurrentMode(e.target.value)
      localStorage.setItem('themeMode' , e.target.value);
      setThemeSettings(false) 
    }

    const setColor =(color) =>{
      setCurrentColor(color)
      localStorage.setItem('colorMode' ,color);
      setThemeSettings(false) 

    }

    const handleClick = (clicked) => {
      setIsClicked({...initialState, [clicked]:true});
    }
    const handleClickClose = (clicked) => {
      setIsClicked({...initialState, [clicked]:false});
    }
  return (
  <StateContext.Provider
    value={{
      activeCart,
       setActiveCart,
        activeMenu,
        setActiveMenu,
        isClicked,
        setIsClicked,
        handleClick,
        handleClickClose,
        screenSize,
        setScreenSize,
        currentColor,
        setCurrentColor,
        currentMode,
        setCurrentMode,
        themeSettings,
        setThemeSettings,
        setMode,
        setColor

    }}
  >
    {children}
  </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext)